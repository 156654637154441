<template>
  <div>
    <van-row type="flex" justify="center">
      <van-col span="18">
        <div>
          <!-- 标题 -->
          <h1 class="header-title"><a style="font-size: 30px;">{{ webSite.title }}</a></h1>
          <!-- 公告栏轮播 -->
          <van-notice-bar left-icon="volume-o" :scrollable="false" :wrapable="true">
            <van-swipe
                vertical
                class="notice-swipe"
                :autoplay="8000"
                :show-indicators="false"
                style="height: 50px;width: 100%"
            >
            <van-swipe-item v-for="(domain, index) in noticeForm" :key="index">
  {{ domain.value }}
</van-swipe-item>
            </van-swipe>
          </van-notice-bar>
          <canvas id="canvas2" hidden=""></canvas>
          <!-- 密钥信息展示 -->
          <van-cell-group inset v-show="!loadingQr">
          <!-- 密钥状态 -->
            <van-cell size="large" title="当前密钥" v-model="token.token" :label="tokenMsg">
          <!-- 判定图标样式-->
              <template #right-icon>
                <!--primary  success danger warning-->
                <span>&nbsp; </span>
                <van-tag round :type="tokenTypeIcon" style="height: 20px;margin-top: 2px;">{{ tokenType }}</van-tag>
                <span>&nbsp; </span>
              </template>
            </van-cell>
          <!-- 更多密钥信息 -->
            <div v-if="token.usingCount">
              <!-- 密钥有效期和用户信息 -->
              <van-cell size="large" title="开始时间" :value="formatDate(token.beginDate)"/>
              <van-cell size="large" title="结束时间" :value="formatDate(token.endDate)"/>
              <van-cell size="large" title="百度账号" :value="token.userName" :label="userMsg">
                <template #right-icon>
                  <span>&nbsp; </span>
                  <van-tag round :type="userTypeIcon" style="height: 20px ;margin-top: 2px;">{{ userType }}</van-tag>
                  <span>&nbsp; </span>
                </template>
              </van-cell>
              <!-- 剩余次数 -->
              <van-cell size="large" title="剩余次数" :value="token.remainingTimes" label="">
                <template #right-icon>
                  <span>&nbsp; </span>
                  <van-tag round type="warning" style="height: 20px ;margin-top: 2px;">
                    {{ token.type === "day" ? '今日剩余' : '总共' }}
                  </van-tag>
                  <span>&nbsp; </span>
                </template>
              </van-cell>
            <!-- 一键复制链接 -->
              <van-cell size="large" title="免输密钥链接" label="">
                <template #right-icon>
                  <span type="primary" @click="copy" style="color: #409EFF">👉👉一键复制</span>
                </template>
              </van-cell>
            </div>

            <!--服务条款-->
            <van-cell size="large" @click="showTos">
              <template #title>
                <span style="color: #409EFF;">
                登陆前须知
                </span>
              </template>
              <template #right-icon>
                已阅读并同意：
                <van-checkbox v-model="showCheckBox" shape="square"></van-checkbox>
              </template>
            </van-cell>
          </van-cell-group>

          <!-- 二维码扫描提示 -->
          <div class="loadingMessage" v-if="loadingQr">
            <span>{{ variableName }}请对准二维码进行扫描</span>
          </div>
          <!-- 扫码登录和上传二维码登录部分 -->
          <canvas id="canvas" style="margin-left: -80px;" hidden=""></canvas>
          <div v-if="flagQR">
            <div @click="scanQrcode" class="saoma mokuai">
              <div style="margin-top:-55px;margin-bottom:36px;font-size: 19px;color: #155724;">电脑/平板/网页，扫码登陆</div>
              <img :src="require('../assets/scan2.png')">
              <div>扫码登陆</div>
              <div v-if="loadingQr" style="color:brown;">请使用手机浏览器访问卡密页面，才能唤醒相机(扫码)</div>
            </div>

            <div class="ts">
              <a :href="webSite.qrUrl" target="_blank">点我看&gt;&gt;电脑/平板/网页 教程</a>
            </div>

            <van-divider/>

            <div class="shangc mokuai">
              <div style="margin-top:-55px;margin-bottom:36px;font-size: 19px;color: #0c5460;">手机端app，上传二维码登陆</div>
              <input @click="showTos1($event)" @change="ocrQrcodeCode($event)" id="upload-input"
                     style="position:absolute;left:0;top:0;opacity: 0;display: inline-block;width: 100%;height: 100%;background-color: red;"
                     type="file" accept="image/*">
              <img :src="require('../assets/qrcode.png')">
              <div>上传二维码登陆</div>
            </div>
            <div class="ts">
              <a :href="webSite.updateUrl" target="_blank">点我看&gt;&gt;手机端app登陆教程</a>
            </div>
          </div>

          <van-divider/>
          <div style="height: 100px"></div>
          <van-divider/>

        </div>
      </van-col>
    </van-row>
            <!--服务条款-->
    <van-dialog :beforeClose="tos" v-model="show" title="登陆须知" cancel-button-text="拒绝" confirm-button-text="我同意"
                show-cancel-button show-confirm-button :lockScroll="false">

      <div class="regulations" v-html="webSite.regulations">
      </div>
    </van-dialog>
    <van-dialog v-model="questionShow" show-confirm-button :title="questionTitle" :lockScroll="false">
      <div class="regulations" v-html="questionHtml">
      </div>
    </van-dialog>

    <van-dialog v-model="showTokenInput"
                :beforeClose="tokenInput"
                title="请输入你的密钥" cancel-button-text="购买密钥" confirm-button-text="确 认"
                :cancel-button-color="'#a20aee'" :confirm-button-color="'#ee0a24'"
                show-cancel-button show-confirm-button :lockScroll="false">
      <van-cell-group>
        <van-field v-model="tokenInputValue" label="" placeholder="请输入你的密钥"/>
      </van-cell-group>
    </van-dialog>

    <van-dialog v-model="isVerification"
                :beforeClose="verification"
                title="操作频繁" confirm-button-text="确 认"
                :cancel-button-color="'#a20aee'" :confirm-button-color="'#ee0a24'"
                show-confirm-button :lockScroll="false" style="text-align: center;">

      <van-image :src="verificationData" height="50" width="160">
        <template v-slot:error>加载失败</template>
      </van-image>

      <van-field
          v-model="verificationCode"
          center
          clearable
          label="验证码"
          placeholder="请输入验证码"
      >
        <template #button>
          <van-button size="small" type="primary" @click="verification()">刷新验证码</van-button>
        </template>
      </van-field>

    </van-dialog>


    <div id="float-icons">
      <float-icons padding="10 10 50 10" scoller="float-icons" class="icons-warp">
        <!-- top-start -->
        <!-- top-end -->
        <van-popover placement="top-end" v-model="showPopover" trigger="click">
          <template #default>
            <div
                style="width: 300px;"
            >
            <van-cell @click="onSelect(item)" v-for="item in questionItemForm" :key="item.key" :title="item.title" :icon="item.icon">

                <template #icon>
                  <van-image
                      width="18"
                      height="18"
                      style="padding-top: 3px;padding-right: 9px"
                      :src="item.icon"
                  />
                </template>
              </van-cell>
            </div>
          </template>

          <template #reference>
            <div class="float-icon-item">
              <img :src="require('@/assets/kf.png')" alt="">
              <span>常见问题</span>
            </div>
          </template>
        </van-popover>

      </float-icons>
    </div>

  </div>
</template>

<script>
// 引入所需的组件和库
import {Toast, Dialog} from 'vant';
import FloatIcons from '@/components/s-icons'
import jsQR from "jsqr";
import router from "@/router";
import Clipboard from 'clipboard';

export default {
  name: "Index",
  data() {
    return {
      // 1获取token 2登陆
      operate: 0,
      isVerification: false,
      verificationData: '',
      verificationKey: '',
      verificationCode: '',
      baiduUrl: '',
      questionShow: false,
      questionTitle: '',
      questionHtml: '',
      wxConfigSuccess: false,
      // 显示输入密钥弹出框
      showTokenInput: false,
      tokenInputValue: '',
      // 客服
      showPopover: false,

      // 服务条款
      show: false,
      showCheckBox: false,
      qrLock: true,
      // 是否是在摄像头
      loadingQr: false,
      loadingQrMessage: "请对准二维码进行扫描",
      // token是否有值 flagQR 决定扫码界面是否隐藏，默认隐藏
      flagQR: false,
      token: {token: ''},
      // Icon primary  success danger warning
      tokenTypeIcon: "success",
      tokenType: "正常",
      tokenMsg: "",

      userTypeIcon: "success",
      userType: "正常",
      userMsg: "",

      // 公告数组信息
      noticeForm: [],
      // 问题信息
      questionItemForm: [],
      // 网站信息
      webSite: [],
      loginLocation: '',
      loginIp: '',
      questionItem: '',
      // 是否是扫码二维码登陆
      flag: ''
    };
  },
  created() {
    this.getWebInfo()
    this.getTokenInfo()
    if (this.isWx()) {
      this.wxConfig();
    }
  },
  components: {
    'float-icons': FloatIcons
  },
  mounted() {

  },
  methods: {
    // 显示弹窗的逻辑...
    showDialog() {
    this.isDialogShown = true;
  },
    // 关闭弹窗的逻辑...
  closeDialog() {
    this.isDialogShown = false;
  },
  
    //时间只显示到当天
    formatDate(date) {
    if (!date) return '';
    const d = new Date(date);
    return `${d.getFullYear()}-${String(d.getMonth() + 1).padStart(2, '0')}-${String(d.getDate()).padStart(2, '0')}`;
  },

    //复制链接
    copy() {
      let text = window.location.href;
      this.$copyText(text).then((e) => {
        console.log('复制成功');
        // Toast.success();
        const toast = Toast.success({
          duration: 0, // 持续展示 toast
          forbidClick: true,
          message: '复制成功\n 收藏你的密钥链接，下次登陆无需重复输入密钥！',
        });
        let second = 3;
        const timer = setInterval(() => {
          second--;
          if (second) {
            toast.message = '复制成功 \n 收藏你的密钥链接，下次登陆无需重复输入密钥！';
          } else {
            clearInterval(timer);
            // 手动清除 Toast
            Toast.clear();
          }
        }, 1000);

      }, (e) => {
        console.log('复制成功');

      })
    },
    verification(action, done) {
      console.log(action)
      if (this.operate === 1) {
        this.getTokenInfo()
        if (typeof done === 'function')
          done()
      } else {
        this.QrLogin(action, done);
      }
    },
    isWx() {
      const ua = window.navigator.userAgent.toLowerCase();
      // alert(ua.indexOf("micromessenger") !== -1)
      return ua.indexOf("micromessenger") !== -1;
    },
    // 微信配置
    wxConfig() {
      let vueThis = this
      const url = encodeURIComponent(location.href.split("#")[0]); //获取当前页面路由
      this.$axios.get("/wxJsSign?url=" + url).then(res => {
        const a = res.data.data
        wx.config({
          debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          appId: a.appId, // 必填，公众号的唯一标识
          timestamp: a.timestamp, // 必填，生成签名的时间戳
          nonceStr: a.nonceStr, // 必填，生成签名的随机串
          signature: a.signature, // 必填，签名
          jsApiList: ["scanQRCode"] // 必填，需要使用的JS接口列表
        });
      })
      wx.error(function (err) {
        // Toast("不支持此功能");
        // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
      });
      wx.ready(function () {
        vueThis.wxConfigSuccess = true;
        // Toast("微信访问");
      });
    },

    // 点击客服按钮
    onSelect(action) {
      // Toast(action.title);
      if (action.type === 'link') {
        window.open(action.value, "target");
        // "https://work.weixin.qq.com/kfid/kfc92b24b2e8580c75c"
      } else {
        // Dialog.alert({
        //   title: action.title,
        //   message: action.value,
        // })
        this.questionShow = true
        this.questionTitle = action.title
        this.questionHtml = action.value
      }
    },
    // 获取网站信息
    getWebInfo() {
      this.$axios.get("/baidu/config/get").then(res => {
        this.webSite = res.data.data

        const notice = JSON.parse(this.webSite.notice);
        this.noticeForm = []
        Object.keys(notice).forEach(key => {
          console.info(key + ':', notice[key])
          this.noticeForm.push({
            value: notice[key]["value"],
            key: notice[key]["key"]
          })
        })
        // 问题
        const question = JSON.parse(this.webSite.question);
        // console.log(question)
        this.questionItemForm = []

        Object.keys(question).forEach(key => {
          console.info(key + ':', question[key])
          this.questionItemForm.push({
            value: question[key]["value"],
            key: question[key]["key"],
            type: question[key]["type"],
            title: question[key]["title"],
            icon: question[key]["icon"],
          })
        })
        this.questionItemForm.reverse()

      })
    },

    // 获取 token 信息  验证码
    getTokenInfo() {
      this.token.token = this.$route.params.pathMatch;
      // 判定是否为空
      console.log(this.token.token)
      if (this.token.token === undefined || this.token.token === '') {
        this.tokenInputValue = ''
        // 输入密钥
        this.showTokenInput = true;
      } else {
        const agree = localStorage.getItem("TokenAgreeFirstEntry-" + this.token.token);
        if (agree != null) {
          if (this.userTypeIcon === "success" && this.tokenTypeIcon === "success")
            this.flagQR = true
          this.qrLock = false
          this.showCheckBox = true
        }
        // 获取是否是第一次进入
        let addInfo = false;
        const item = localStorage.getItem("TokenFirstEntry-" + this.token.token);
        if (item == null) {
          // 插入日志
          localStorage.setItem("TokenFirstEntry-" + this.token.token, this.token.token)
          addInfo = true;
        }

        this.$axios.get("/baidu/token/findToken?token=" + this.token.token + "&addInfo=" + addInfo
            + "&verCode=" + this.verificationCode
            + "&verKey=" + this.verificationKey
            ,).then(res => {
          console.log(res)
          if (res.data.data.image !== undefined) {
            this.verificationCode = ''
            this.operate = 1
            this.isVerification = true;
            this.verificationData = res.data.data.image
            this.verificationKey = res.data.data.key
            return;
          }

          //密钥非正常状态的弹窗意义
          if (res.data.data.no !== undefined) {
            if (res.data.data.no === "0") {
              this.token = res.data.data.data
              this.flagQR = true
            } else {
              // 非正常数据状态判定
              Toast.fail({
                // 持续展示 toast
                // duration: 0,
                forbidClick: true,
                message: res.data.data.msg,
              });
              // primary  success danger warning
              if (res.data.data.no.indexOf(",-1") !== -1) {
                // 密钥错误
                this.tokenInputValue = this.token.token
                this.showTokenInput = true;
                this.tokenTypeIcon = "danger"
                this.tokenType = "异常"
                this.tokenMsg = res.data.data.msg
                return;
              }
              this.token = res.data.data.data
              if (res.data.data.no.indexOf(",-2") !== -1) {
                // 密钥找不到绑定账号
                this.tokenTypeIcon = "danger"
                this.tokenType = "异常"
                this.tokenMsg = res.data.data.msg

                this.userTypeIcon = "danger"
                this.userType = "异常"
                this.userMsg = res.data.data.msg


                this.tokenInputValue = this.token.token
                this.showTokenInput = true;
              }
              if (res.data.data.no.indexOf(",1") !== -1 || res.data.data.no.indexOf(",2") !== -1
                  || res.data.data.no.indexOf(",3") !== -1) {
                // 时间未到或未开始 使用次数为0 密钥禁用
                this.tokenTypeIcon = "warning"
                this.tokenType = "到期"
                this.userMsg = "账号已到期"
                this.tokenMsg = res.data.data.msg

                this.tokenInputValue = this.token.token
                this.showTokenInput = true;
              }
              if (res.data.data.no.indexOf(",4") !== -1) {
                // 账号禁用
                this.userTypeIcon = "danger"
                this.userType = "禁用"
                this.userMsg = "违规使用，已被封禁"
                this.tokenInputValue = this.token.token
                this.showTokenInput = true;
              }

            }
          }
        })

      }
    },


    // 扫码登陆后
    QrLogin(action, done) {
      const vueThis = this
      if (action === 'confirm') {
        // 登陆
        console.log("登陆url：" + this.url)

        this.$axios.post("/baidu/token/qrLogin",{
              "token": this.token.token,
              "loginUrl": this.baiduUrl,
              "verCode": this.verificationCode,
              "verKey": this.verificationKey,
            })
            .then(res => {
          console.log(res)
          if (res.data.data.image !== undefined) {
            this.verificationCode = ''
            this.operate = 2
            this.isVerification = true;
            this.verificationData = res.data.data.image
            this.verificationKey = res.data.data.key
            return;
          }

          function beforeClose(action2, done2) {
            if (vueThis.flag)
              vueThis.$router.go(0);
            done2();
          }

          if (res.data.data !== null) {
            if (res.data.data.no === "0") {
        const customerServiceLink = 'https://sm.hezuvip.cn/kf/';
        const message = `有问题，可联系客服处理：<a href="${customerServiceLink}" target="_blank">点击这里</a>`;
      Dialog.alert({
        title: '登录成功',
        message: message,
        dangerouslyUseHTMLString: true 
      });
            } else {
              Dialog.alert({
                title: '出错了',
                message: res.data.data.msg
                , beforeClose
              })
              if (res.data.data.no === "-1" || res.data.data.no === "1" || res.data.data.no === "2"
                  || res.data.data.no === "3" || res.data.data.no === "4") {
                this.tokenInputValue = this.token.token
                this.showTokenInput = true;
              }
            }
          }
        }).catch(error => {
          function beforeClose(action3, done3) {
            if (vueThis.flag)
              vueThis.$router.go(0);
            done3();
          }

          Dialog.alert({
            title: '出错了',
            message: error.message
            , beforeClose
          })
        })
        done();
      } else {
        // 取消按钮
        done();
      }
      // 扫码登陆重新加载次数减少加上会出问题 todo
      // await this.getTokenInfo()
      // 重新加载界面
    },

    loadingQrMessageChange(loadingQrMessage, loadingQr) {
      this.loadingQrMessage = loadingQrMessage
      this.loadingQr = loadingQr
    },
    wxQrSuccess(code) {
      // alert(code)
      let vueThis = this
      let reg = /wappass.baidu.com\/wp\/\?qrlogin/;
      if (code.match(reg)) {
        this.baiduUrl = code

        function beforeClose(action, done) {
          vueThis.flag = true
          vueThis.QrLogin(action, done);
        }

        Dialog.confirm({
          title: '确认要登录吗？',
          message: '扫码成功，请确认操作',
          beforeClose
        });
      } else {
        Dialog.alert({
          title: '出错了',
          message: "识别失败，请检查是否是百度网盘二维码。可参考右下角常见问题解决"
        });
      }
    },
    // 扫码
    scanQrcode() {
      if (this.qrLock) {
        this.show = true;
        return;
      }
      const vueThis = this
      // alert("微信1" + this.isWx())
      // alert("微信2" + this.wxConfigSuccess)
      // 判定是否微信，微信执行逻辑
      if (this.isWx() && this.wxConfigSuccess) {
        // alert("调用");
        wx.scanQRCode({
          // 微信扫一扫接口
          desc: "scanQRCode desc",
          needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
          // scanType: ["qrCode", "barCode"], // 可以指定扫二维码还是一维码，默认二者都有
          scanType: ["qrCode"], // 可以指定扫二维码还是一维码，默认二者都有
          success(res) {
            setTimeout(function () {
              const code = res.resultStr; // 当needResult 为 1 时，扫码返回的结果
              vueThis.wxQrSuccess(code)
            }, 1000)
          },
          fail(res) {
            setTimeout(function () {
              Toast(res.errMsg);
            }, 1000)
          }
        });
        return;
      }
      // 创建video 组件
      const video = document.createElement("video");
      const canvasElement = document.getElementById("canvas");
      const canvas = canvasElement.getContext("2d");

      //扫码成功后画线
      function drawLine(begin, end, color) {
        canvas.beginPath();
        canvas.moveTo(begin.x, begin.y);
        canvas.lineTo(end.x, end.y);
        canvas.lineWidth = 4;
        canvas.strokeStyle = color;
        canvas.stroke();
      }

      var constraints = {
        video: {
          width: {min: 1024, ideal: 1280, max: 1920},
          height: {min: 776, ideal: 720, max: 1080},
          facingMode: "environment"
        }
      };
      navigator.getUserMedia = navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.msGetUserMedia;
      if (navigator.mediaDevices) {
        // 调用摄像头获取数据
        navigator.mediaDevices.getUserMedia(constraints).then(function (stream) {
          video.srcObject = stream;
          video.setAttribute("playsinline", true); // required to tell iOS safari we don't want fullscreen
          video.setAttribute('webkit-playsinline', true);
          video.play();
          requestAnimationFrame(tick);
        });
      } else if (navigator.getUserMedia) {
        navigator.getUserMedia(constraints).then(function (stream) {
          video.srcObject = stream;
          video.setAttribute("playsinline", true); // required to tell iOS safari we don't want fullscreen
          video.setAttribute('webkit-playsinline', true);
          video.play();
          requestAnimationFrame(tick);
        });
      } else {
        Dialog.alert({
          title: '出错了',
          message: "对不起：未识别到扫描设备！"
        });
      }


      function tick() {
        if (video.readyState === video.HAVE_ENOUGH_DATA) {
          // 显示摄像头的video
          canvasElement.hidden = false;
          // 显示提示
          vueThis.loadingQrMessageChange("请对准二维码进行扫描aaaaa", true)
          canvasElement.height = video.videoHeight;
          canvasElement.width = video.videoWidth;
          canvas.drawImage(video, 0, 0, canvasElement.width, canvasElement.height);
          const imageData = canvas.getImageData(0, 0, canvasElement.width, canvasElement.height);
          const code = jsQR(imageData.data, imageData.width, imageData.height, {
            inversionAttempts: "dontInvert",
          });
          if (code) {
            console.log("识别成功" + code.data)
            drawLine(code.location.topLeftCorner, code.location.topRightCorner, "#FF3B58");
            drawLine(code.location.topRightCorner, code.location.bottomRightCorner, "#FF3B58");
            drawLine(code.location.bottomRightCorner, code.location.bottomLeftCorner, "#FF3B58");
            drawLine(code.location.bottomLeftCorner, code.location.topLeftCorner, "#FF3B58");
            // 识别成功
            let reg = /wappass.baidu.com\/wp\/\?qrlogin/;
            if (code.data.match(reg)) {
              vueThis.baiduUrl = code.data

              function beforeClose(action, done) {
                vueThis.flag = true
                vueThis.QrLogin(action, done);
              }

              Dialog.confirm({
                title: '确认要登录吗？',
                message: '扫码成功，请确认操作',
                beforeClose
              });
            } else {
              Dialog.alert({
                title: '出错了',
                message: "识别失败，请检查是否是百度网盘二维码。可参考右下角常见问题解决"
              });
            }
          }
        }
        requestAnimationFrame(tick);
      }
    },
    // 上传图片
    ocrQrcodeCode(input) {
      if (this.qrLock) {
        this.show = true;
        return;
      }
      // console.log(this)
      const vueThis = this
      try {
        const file = input.target.files[0];
        const img = document.createElement('img')
        const reader = new FileReader();
        reader.onload = function (e) {
          img.src = e.target.result
          img.onload = function () {
            const canvasElement = document.getElementById("canvas2");
            const canvas = canvasElement.getContext("2d");

            canvasElement.width = img.width
            canvasElement.height = img.height
            canvas.drawImage(img, 0, 0, canvasElement.width, canvasElement.height);
            const imageData = canvas.getImageData(0, 0, canvasElement.width, canvasElement.height);
            const code = jsQR(imageData.data, imageData.width, imageData.height, {
              inversionAttempts: "dontInvert",
            });
            // 识别成功
            if (code != null)
              console.log("识别成功" + code.data)
            let reg = /wappass.baidu.com\/wp\/\?qrlogin/;
            if (code != null && code.data.match(reg)) {
              // Dialog.alert({
              //   title: '获取到链接',
              //   message: code.data
              // })
              // this. = true;
              // outputData.innerText = '扫码成功，请确认操作';
              vueThis.baiduUrl = code.data

              function beforeClose(action, done) {
                vueThis.flag = false
                vueThis.QrLogin(action, done);
              }

              Dialog.confirm({
                title: '确认要登录吗？',
                message: '扫码成功，请确认操作',
                beforeClose
              });
            } else {
              // outputData.innerText = '识别失败，请检查是否是百度网盘二维码。可参考右下角常见问题解决';
              Dialog.alert({
                title: '出错了',
                message: "识别失败，请检查是否是百度网盘二维码。可参考右下角常见问题解决"
              });
            }
          }
        }
        // 读取完成后触发 onload 事件
        reader.readAsDataURL(file)
        document.getElementById("upload-input").value = ''
      } catch (err) {
        //alert(1)
        Dialog.alert({
          title: '出错了',
          message: err.message
        })
      }
    },
    //end
    // 服务条款
    showTos1(e) {
      console.log("登陆须知" + this.showCheckBox)
      if (this.qrLock) {
        this.show = true;
        e.preventDefault();
      }
    },
    showTos() {
      console.log("登陆须知" + this.showCheckBox)
      this.show = true
    },
    tos(action, done) {
      if (action === "cancel") {
        // 不同意
        this.flagQR = false
        this.showCheckBox = false
        localStorage.removeItem("TokenAgreeFirstEntry-" + this.token.token);
      } else {
        localStorage.setItem("TokenAgreeFirstEntry-" + this.token.token, "同意");
        // 同意
        if (this.userTypeIcon === "success" && this.tokenTypeIcon === "success")
          this.flagQR = true
        this.qrLock = false
        this.showCheckBox = true

      }
      done()
    },
    tokenInput(action, done) {
      if (action === "cancel") {
        // 购买密钥
        console.log("购买密钥")
        window.location.href = this.webSite.buyUrl
        done()
      } else {
        // 确认输入
        console.log("输入密钥" + this.tokenInputValue)
        window.location.href = "/qrLogin/" + this.tokenInputValue
        // router.push({path: "/qrLogin/", params: {pathMatch: this.tokenInputValue}})
      }
      // done()
    },
    utf8ByteToUnicodeStr(utf8Bytes) {
      var unicodeStr = "";
      for (var pos = 0; pos < utf8Bytes.length;) {
        var flag = utf8Bytes[pos];
        var unicode = 0;
        if ((flag >>> 7) === 0) {
          unicodeStr += String.fromCharCode(utf8Bytes[pos]);
          pos += 1;

        } else if ((flag & 0xFC) === 0xFC) {
          unicode = (utf8Bytes[pos] & 0x3) << 30;
          unicode |= (utf8Bytes[pos + 1] & 0x3F) << 24;
          unicode |= (utf8Bytes[pos + 2] & 0x3F) << 18;
          unicode |= (utf8Bytes[pos + 3] & 0x3F) << 12;
          unicode |= (utf8Bytes[pos + 4] & 0x3F) << 6;
          unicode |= (utf8Bytes[pos + 5] & 0x3F);
          unicodeStr += String.fromCharCode(unicode);
          pos += 6;

        } else if ((flag & 0xF8) === 0xF8) {
          unicode = (utf8Bytes[pos] & 0x7) << 24;
          unicode |= (utf8Bytes[pos + 1] & 0x3F) << 18;
          unicode |= (utf8Bytes[pos + 2] & 0x3F) << 12;
          unicode |= (utf8Bytes[pos + 3] & 0x3F) << 6;
          unicode |= (utf8Bytes[pos + 4] & 0x3F);
          unicodeStr += String.fromCharCode(unicode);
          pos += 5;

        } else if ((flag & 0xF0) === 0xF0) {
          unicode = (utf8Bytes[pos] & 0xF) << 18;
          unicode |= (utf8Bytes[pos + 1] & 0x3F) << 12;
          unicode |= (utf8Bytes[pos + 2] & 0x3F) << 6;
          unicode |= (utf8Bytes[pos + 3] & 0x3F);
          unicodeStr += String.fromCharCode(unicode);
          pos += 4;

        } else if ((flag & 0xE0) === 0xE0) {
          unicode = (utf8Bytes[pos] & 0x1F) << 12;
          ;
          unicode |= (utf8Bytes[pos + 1] & 0x3F) << 6;
          unicode |= (utf8Bytes[pos + 2] & 0x3F);
          unicodeStr += String.fromCharCode(unicode);
          pos += 3;

        } else if ((flag & 0xC0) === 0xC0) { //110
          unicode = (utf8Bytes[pos] & 0x3F) << 6;
          unicode |= (utf8Bytes[pos + 1] & 0x3F);
          unicodeStr += String.fromCharCode(unicode);
          pos += 2;

        } else {
          unicodeStr += String.fromCharCode(utf8Bytes[pos]);
          pos += 1;
        }
      }
      return unicodeStr;
    }
  }
}
</script>


<style lang="less" coped>
//设置弹窗公告的css
.notice-swipe .van-swipe-item {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px; /* 确保和 van-swipe 的高度一致 */
  }
canvas {
  pointer-events: none;
}

.icons-warp {
  //border-radius: 25px;
  .float-icon-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 50px;
    height: 50px;

    img {
      width: 25px;
      height: 25px;
      margin-bottom: 3px;
    }

    span {
      font-size: 9px;
      color: #666666;
    }
  }
}


.regulations {
  height: 500px;
  overflow: auto;
  word-wrap: break-word;
  word-break: normal;
  margin: 20px 10px 20px 20px;
}

.header-title {
  color: #409EFF;
  text-shadow: 1px 1px 20px #7d7d7d;
  text-decoration: none;
  text-align: center;
}

.loadingMessage {
  color: #d73636;
  text-align: center;
}

.saoma {
  outline: none;
  border-radius: 10px;
  box-shadow: 1px 5px 1px 1px #ddd;
  font-size: 16px;
  width: 95%;
  height: 157px;

  border-top: 50px solid #d4edda;
  border-right: 1px solid #ededed;
  border-bottom: 1px solid #ededed;
  border-left: 1px solid #ededed;

  text-align: center;
  padding: 20px 0px;
  margin: 10px auto;
}

.shangc {
  outline: none;
  padding: 20px 0px;
  position: relative;
  border-radius: 10px;
  box-shadow: 1px 5px 1px 1px #ddd;
  font-size: 16px;
  width: 95%;
  height: 150px;
  border-top: 50px solid #d1ecf1;
  border-right: 1px solid #ededed;
  border-bottom: 1px solid #ededed;
  border-left: 1px solid #ededed;

  text-align: center;
  margin: 10px auto;
}

.mokuai img {
  width: 100px;
  height: 100px;
}

//.mokuai:hover {
//  border: 1px solid #0d5ed8;
//}

.ts {
  padding: 10px 10px;
  text-align: center;
}

.ts-a {
  padding: 10px 10px;
  text-align: center;
}

.ts-a a:hover {
  color: #409EFF;
}

.ts a {
  background-color: #409EFF;
  color: #ffffff;
  padding: 8px;
  border-radius: 5px;
}

.ts a:hover {
  background-color: rgb(61, 122, 236);
  color: #000102;
}

.custom-title {
  margin-right: 4px;
  vertical-align: middle;
}

.search-icon {
  font-size: 16px;
  line-height: inherit;
}

.van-overlay {
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
}

</style>